<template>
  <div :style="{ backgroundImage: `url(${bannerImage})`}">
    <loading 
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>

    <h2>Cupones y descuentos</h2>
    <p>Disfruta de los descuentos con las empresas aliadas. Válido para usuarios con paquetes 
      de paseos o adiestramiento activo (mayor a 11 servicios).
    </p>

    <b-alert v-model="noPackageActive" variant="warning" dismissible>
      ¡No cuentas con paquete de servicios activo!           
    </b-alert>
     <b-alert v-model="flagCouponSent" variant="success" dismissible>
      {{couponMessage}}       
    </b-alert>
    {{loadItems}}

    <section v-if="items.length > 0"> 
      <b-row>
        <b-col lg="10" offset-lg="1">

          <b-row>
            <b-col lg="6" v-for="item in items" v-bind:key="item.id">
              <!-- <CCard accent-color="info"> -->
              <CCard>
                <CCardHeader>
                  <b-row>
                    <b-col 
                      lg="4" md="5" 
                      style="text-align: center"
                      class="discount-label">
                      <h4>
                      {{item.porc_dscto == "" ? '$' : ''}}
                      {{item.porc_dscto != "" ? item.porc_dscto  : item.bono|thousand}}
                      {{item.porc_dscto != "" ? '%' : ''}}
                      
                      </h4>
                      <h5>
                        {{item.porc_dscto != "" ? 'Descuento'  : 'Bono'}}
                      </h5>
                                            
                    </b-col>
                    <b-col lg="7" md="7">                      
                      <strong>{{item.nombre}}</strong> <br>
                      {{item.aliado.nombre}}
                      <b-row>
                        <b-col class="mb-3" lg="6"  sm="6">
                          <b-button                             
                            size="sm"
                            style="width:100%;"
                              @click="item.expandAccordion = item.expandAccordion === 0 ? false : 0">
                            {{item.expandAccordion === 0 ? 'Ver menos' : 'Ver mas'}}  
                            <CIcon :name="item.expandAccordion === 0 ? 'cilEnvelopeClosed' : 'cilEnvelopeOpen'" size="sm" height="48" alt="Logo" />                 
                          </b-button> 
                        </b-col> 
                        <b-col class="mb-3" lg="6"  sm="6"> 
                          <b-button                             
                            size="sm"
                            style="width:100%;"
                            @click="getCoupon(item)">
                              Obtener código                                        
                          </b-button> 
                        </b-col>
                      </b-row>                    
                    </b-col>                    
                  </b-row>                
                </CCardHeader>
                <CCollapse :show="item.expandAccordion === 0">
                  <CCardBody>
                    <b-row>
                      <b-col lg="12">
                        <strong>{{item.aliado.descripcion}}</strong>
                      </b-col>
                      <b-col lg="12">
                        <strong>Dirección:</strong> {{item.aliado.direccion}}
                      </b-col>
                      <b-col lg="12">
                        <strong>Teléfono:</strong> 
                        <div 
                          v-for="(celular, index) in item.aliado.celular" 
                          v-bind:key="index">
                            {{celular}}
                        </div>                        
                      </b-col>                                           
                      <b-col lg="12">
                        <strong>Horario:</strong> 
                        <div 
                          v-for="(horario, index) in item.aliado.horario" 
                          v-bind:key="index">
                            {{horario}}
                        </div>
                      </b-col>
                       <b-col lg="12">
                        <strong>Email:</strong> {{item.aliado.email}}
                      </b-col>
                    </b-row>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </b-col>
          </b-row>


        </b-col>
      </b-row>
    </section> 
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment';
export default {
  name: "dashboard",
  components: {Loading},
  data () {
    return {
      loading: true,
      bannerImage: require("@/assets/images/bonds3.png"),
      userDbId: null,
      items: [],
      noPackageActive: true,
      userProfile: null,
      flagCouponSent: false,
      couponMessage: '',
      cityId: null,
    }
  },
  created(){
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    this.cityId = this.userProfile.ciudades.id

    if(this.userProfile) {
      this.userDbId = this.userProfile.id  
      this.fetchBenefits();
    } else {
      this.loading = false;
    }
  },
  computed: {
    loadItems () {
      if(this.noPackageActive == false){        
        this.fetchBenefits()
        return ''
      }
    }
  },
  methods: {
    // postGetClientPayments() {
    //   let payload = {
    //     id: this.userDbId,
    //   };              
    //   let getOrders = this.$firebase.functions().httpsCallable('getOrdersByClient')
    //   getOrders(payload).then((result) => {
    //     let data = result.data.data;
    //     // console.log(data)
    //     if(data.length == 0) {
    //       this.flagAlertPayments = true
    //     } else {          
    //       data.map((item) => {
    //         item.fecha_operacion = moment(item.fecha_operacion).format('dddd, DD/MMMM/YY')
    //         item.debe = item.debe == 'null' ?  0 : item.debe
    //         item.expandAccordion = 1
    //         item.articulo_id = (item.articulo_id.split(' '))[0]
    //         if(+item.cantidad > 11 && +item.servicios_restantes > 0){
    //           this.noPackageActive = false
    //           return true
    //         } 
    //       })
    //       // this.items = data
    //     }        
    //   }).catch((error) => {
    //     console.log(error);
    //     this.loading = false
    //   })
    // },
    fetchBenefits() {
      const payload = {
        userDbId: this.userDbId,
        cityId: this.cityId
      };

      let getBenefits = this.$firebase.functions().httpsCallable('getBenefits');
      getBenefits(payload).then((response) => {        
        let data = response.data.data

        data.map((item) => {
          item.expandAccordion = 1
          item.aliado.celular = JSON.parse(item.aliado.celular)
          item.aliado.horario = JSON.parse(item.aliado.horario)
        })
        this.items = data     
        if(this.items.length > 0){
          this.noPackageActive = false;
        }
        
               
        // console.log(data)
      }).catch((error) => {
        console.log(error)
      }).finally(_=>this.loading=false)
    },
    getCoupon(item){      
      let payload = {
        allyName: item.aliado.nombre,
        allyAddress: item.aliado.direccion,
        allyPhone: item.aliado.celular[0],
        allyEmail: item.aliado.email,
        benefitName: item.nombre,
        benefitCoupon: item.bono != null ? item.bono : item.porc_dscto,
        benefitType: item.bono != null ? '$' : '%',
        userEmail: this.userProfile.email,
        userName: this.userProfile.nombre,
      };
      // console.log(payload);
      this.loading = true
      let getCoupon = this.$firebase.functions().httpsCallable('getCouponCode');
      getCoupon(payload).then((response) => {
        console.log(response.data)

        let userName = this.userProfile.nombre.split(' ')[0] 
        this.couponMessage = '¡'+ userName +
        ' el código de descuento fue enviado a tu correo! Revisa en bandeja o spam';
        this.flagCouponSent = true;
        
      }).catch((error) => {
        console.log(error);
      }).finally(_=> {
        this.scrollToTop()
        this.loading=false
      })
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },

  }

}
</script>

<style scoped>  
  .discount-label{
    border-style: solid;
    border-width: 1.5px;
    border-radius: .25rem;
    color: #faad63;       
  }
</style>